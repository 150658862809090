import DOMPurify from 'dompurify';
import { forwardRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { useWindowSize } from '../../../../hooks/useWindowSize';
import { convertDateFormat } from '../../../../utils/convertDateFormat';
import styles from '../News.module.scss';

export const NewsItem = forwardRef(({ news, newsItem }, ref) => {
  const navigate = useNavigate();
  const { isMobile } = useWindowSize();
  const handleNewsClick = () => {
    navigate(`/news/${newsItem.id}`, { state: { news } });
  };

  return (
    <div ref={ref} className={styles.newsItem} onClick={handleNewsClick}>
      <img
        className={styles.newsImage}
        src={`${import.meta.env.VITE_STATIC_DOMAIN}${newsItem.main_image.replace('__domain__', '')}`}
        alt={newsItem.local_en.alt_desc}
      />
      <div className={styles.newsPanel}>
        <div className={styles.panelInfo}>
          <div>
            <h5>{newsItem.local_ru?.title}</h5>
            {!isMobile && (
              <div
                className={styles.smallText}
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(newsItem.local_ru.short_text),
                }}
              />
            )}
          </div>
          <span className={styles.date}>{convertDateFormat(newsItem.publishedAt)}</span>
        </div>
      </div>
    </div>
  );
});
