import { useQuery } from '@tanstack/react-query';
import { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useCrmCall } from '../../../api/useCrmCall';
import { GamesModalContext } from '../../../context/GamesModalContext';
import { NotificationsContext } from '../../../context/NotificationsContext';
import { UserContext } from '../../../context/UserContext';

export const GamePage = () => {
  const { state } = useLocation();
  const { filteredGames } = useContext(GamesModalContext);
  const { setNotifications } = useContext(NotificationsContext);
  const {
    userState: { user },
  } = useContext(UserContext);
  const { postCrmRequest } = useCrmCall();

  const navigate = useNavigate();

  const game = state?.game ?? filteredGames?.find(item => item?.id === state?.id);

  const userId = user?.userId || null;

  useEffect(() => {
    if (!userId) navigate('/');
  }, [userId]);

  const {
    data: gameUrl = '',
    error,
    isLoading,
  } = useQuery({
    queryKey: ['gameSession', game?.key, game?.url_key],
    queryFn: () => postCrmRequest('/games/game_session', { gameKey: game?.key || game?.url_key }),
    enabled: Boolean(userId),
    refetchOnWindowFocus: false,
    retryDelay: 300,
  });

  useEffect(() => {
    if (error) {
      setNotifications({ type: 'error', message: error?.response?.data?.message });
    }
  }, [error]);

  useEffect(() => {
    const updateHeight = () => {
      const rootElement = document.querySelector('#root');
      if (rootElement) rootElement.style.padding = 0;

      // Реальная высота экрана за вычетом панели управления
      const height = window.innerHeight;
      document.documentElement.style.setProperty('--app-height', `${height}px`);
    };

    updateHeight();
    window.addEventListener('resize', updateHeight);

    return () => window.removeEventListener('resize', updateHeight);
  }, []);

  useEffect(() => {
    const rootElement = document.querySelector('#root');
    if (rootElement) rootElement.style.padding = 0;
  }, []);

  return isLoading ? (
    <p>Loading...</p>
  ) : (
    <iframe
      src={gameUrl}
      title={game?.key}
      width='100%'
      height='100%'
      style={{
        borderRadius: 0,
      }}
      allowFullScreen
    />
  );
};
