import axios from 'axios';

export const verifyEmail = async data => {
  const url =
    import.meta.env.VITE_IS_DEV === 'true'
      ? `${import.meta.env.VITE_API_URL}`
      : `${window.location.protocol}//api.${window.location.hostname}`;

  try {
    const response = await axios.put(`${url}/auth/change-userinfo`, data);

    if (response.status === 200) {
      const result = await axios.put(`${url}/auth/verify-email`, data);

      return result?.status;
    }
  } catch (error) {
    throw error?.response?.data?.message;
  }
};
