import axios from 'axios';

export const getNews = async ({ pageParam = 1 }) => {
  const url =
    import.meta.env.VITE_IS_DEV === 'true'
      ? `${import.meta.env.VITE_API_URL}`
      : `${window.location.protocol}//api.${window.location.hostname}`;

  try {
    const response = await axios.get(`${url}/news`, {
      params: { page: pageParam, perPage: 4 },
    });

    return response.data;
  } catch (error) {
    throw error?.response?.data?.message;
  }
};
