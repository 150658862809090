import a2a from '../../../../assets/components/Deposit/a2a.svg?react';
import cards from '../../../../assets/components/Deposit/cards.svg?react';
import sbp from '../../../../assets/components/Deposit/sbp.svg?react';

const icons = [
  { name: 'SBP P2P RUB', path: sbp },
  { name: 'MC/VISA/MIR P2P RUB', path: cards },
  { name: 'A2A RUB', path: a2a },
];

export const getPaymentIcon = name => {
  const method = icons.find(item => item?.name === name);
  const Icon = method?.path;
  return <Icon />;
};
