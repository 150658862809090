import { useContext } from 'react';

import { GamesModalContext } from '../../../../../../context/GamesModalContext';
import { useDisableInteractionOnScroll } from '../../../../../../hooks/useDisableInteractionOnScroll';
import { capitalizeFirstLetter } from '../../../../../../utils/capitalizeFirstLetter';
import { BackButton } from '../../../../../atoms/BackButton/BackButton';
import Spinner from '../../../../../atoms/Spinner/Spinner';
import { GameItem } from '../GameItem/GameItem';
import styles from './ModalContentFilteredGames.module.scss';

export const ModalContentFilteredGames = () => {
  const { filteredGames, modalName, modalContent, isLoading } = useContext(GamesModalContext);
  useDisableInteractionOnScroll();

  if (!isLoading && filteredGames.length === 0) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <p>Ничего не найдено</p>
      </div>
    );
  }

  return isLoading ? (
    <Spinner />
  ) : (
    <div className={styles.gamesContent}>
      <div className={styles.titleContainer}>
        <BackButton />
        <p className={styles.title}>{capitalizeFirstLetter(modalName?.current || modalContent)}</p>
      </div>
      <div data-block-interactions={true} className={styles.gamesContainer}>
        {filteredGames?.map(game => (
          <GameItem key={game?.id} game={game} />
        ))}
      </div>
    </div>
  );
};
