import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import SearchIcon from '../../../assets/components/SearchBar/search.svg?react';
import { useClickOutside } from '../../../hooks/useClickOutside';
import { GameItem } from '../../pages/_modals/GamesModal/ui/GameItem/GameItem';
import styles from './SearchBar.module.scss';
import { getIconByType } from './utils/getIconByType';

export const SearchBar = ({ style }) => {
  const [value, setValue] = useState('');
  const [debouncedValue, setDebouncedValue] = useState('');
  const [searchResults, setSearchResults] = useState();
  const ref = useRef(null);
  // const navigate = useNavigate();

  const handleChange = e => {
    setValue(e.target.value);
  };

  useEffect(() => {
    if (value.length > 2) {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, 500);

      return () => {
        clearTimeout(handler);
      };
    }
  }, [value]);

  // для локалки `https://test-elasticbackend.chcplatform.net/search?query=${debouncedValue}&language=ru`
  // для не локалки `${window.location.origin}/search?query=${debouncedValue}&language=ru`
  useEffect(() => {
    if (debouncedValue) {
      const fetchData = async () => {
        const { data } = await axios.get(
          `${window.location.origin}/search?query=${debouncedValue}&language=ru`,
        );
        setSearchResults(data);
      };

      fetchData();
    }
  }, [debouncedValue]);

  // const handlePageClick = page => {
  //   navigate(`/${page}`);
  // };

  useClickOutside(ref, () => setSearchResults(null));

  return (
    <div className={styles.searchContainer} ref={ref}>
      <div className={styles.searchBar} style={style}>
        <SearchIcon width='40' height='40' viewBox='0 0 40 40' />
        <input className={styles.searchInput} value={value} onChange={handleChange} type='text' placeholder='Поиск' />
      </div>

      {searchResults && (
        <div className={styles.searchResults}>
          {/*<div className={styles.searchResultsPages}>*/}
          {/*  {searchResults*/}
          {/*    ?.filter(item => item?.type !== 'game')*/}
          {/*    .slice(0, 5)*/}
          {/*    .map(item => {*/}
          {/*      const Icon = getIconByType(item?.type);*/}
          {/*      return (*/}
          {/*        <div*/}
          {/*          key={item?.title_ru}*/}
          {/*          className={styles.pageResult}*/}
          {/*          onClick={() => handlePageClick(item?.url_key)}*/}
          {/*        >*/}
          {/*          <Icon /> <p className={styles.pageResultText}>{item?.title_ru}</p>*/}
          {/*        </div>*/}
          {/*      );*/}
          {/*    })}*/}
          {/*</div>*/}
          <div className={styles.searchResultsGames}>
            {searchResults?.map(item => item?.type === 'game' && <GameItem key={item?.id} game={item} />)}
          </div>
        </div>
      )}
    </div>
  );
};
