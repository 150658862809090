import { clsx } from 'clsx';

import LogoSupport from '../../../../assets/components/Header/Support.svg?react';
import styles from './Support.module.scss';

const Support = () => {
  return (
    <div className={clsx(styles.wrapper, 'show-verbox')}>
      <LogoSupport />
      <p className={styles.text}>24/7</p>
    </div>
  );
};

export default Support;
