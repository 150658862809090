import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { registerUrl } from '../../../../../../constants';
import { UserContext } from '../../../../../../context/UserContext';
import styles from './GameItem.module.scss';

export const GameItem = ({ game }) => {
  const {
    userState: { isAuthenticated },
  } = useContext(UserContext);
  const navigate = useNavigate();
  const imgSource = game?.main_image || game?.img;
  const img = imgSource?.replace(/__domain__/g, import.meta.env.VITE_STATIC_DOMAIN);
  const gameKey = game?.key || game?.url_key;

  const handleGameClick = () => {
    if (!isAuthenticated) {
      window.location = registerUrl;
    } else {
      navigate(`/game/${gameKey}`, { state: { game } });
    }
  };

  return (
    <div onClick={handleGameClick} className={styles.gameIco}>
      <img src={img} alt={`VOCXOD_${game?.key}`} />
    </div>
  );
};
