import clsx from 'clsx';
import { memo, useContext } from 'react';
import Modal from 'react-modal';

import Avatar from '../../../../assets/components/Header/Avatar.svg?react';
import TribeDark from '../../../../assets/components/Header/TribeDark.svg?react';
import TribeLight from '../../../../assets/components/Header/TribeLight.svg?react';
import NotVerifSvg from '../../../../assets/components/Profile/NotVerifSvg.svg?react';
import VerifSvg from '../../../../assets/components/Profile/VerifSvg.svg?react';
import { ThemeContext } from '../../../../context/ThemeContext';
import { UserContext } from '../../../../context/UserContext';
import { useWindowSize } from '../../../../hooks/useWindowSize';
import { BackButton } from '../../../atoms/BackButton/BackButton';
import { CloseButton } from '../../../atoms/CloseButton/CloseButton';
import styles from './ModalComponent.module.scss';

export const ModalComponent = memo(
  ({ isOpen, onRequestClose, modalContent, modalName, handleGoBack, Content, closeModal }) => {
    const { userState } = useContext(UserContext);
    const client = userState?.crmUserData;
    const { isMobile } = useWindowSize();
    const { theme } = useContext(ThemeContext);

    return (
      <>
        <Modal
          isOpen={isOpen}
          onRequestClose={onRequestClose}
          shouldCloseOnOverlayClick={true}
          className={clsx(styles.modal, modalContent.current === 'profile' ? styles.profileModal : '')}
          overlayClassName={styles.modalOverlay}
          style={{ overflowY: modalContent === 'profile' ? 'visible' : 'scroll' }}
        >
          {modalContent.current === 'profile' && isMobile && (
            <div className={styles.avatar}>
              <Avatar />
              <div className={styles.profileVerif}>{!client?.is_verified ? <VerifSvg /> : <NotVerifSvg />}</div>
            </div>
          )}

          <div className={styles.modalHeader}>
            {modalContent.current !== 'profile' && (
              <div className={styles.changeToProfileButton} onClick={handleGoBack}>
                {modalContent.current !== 'withdrawal-step4' && <BackButton />}
                {modalName || modalContent?.current}
              </div>
            )}
            {!isMobile && <div className={styles.tribe}>{theme === 'dark' ? <TribeDark /> : <TribeLight />}</div>}
            <div className={styles.headerContentWrapper}>
              <CloseButton onClick={closeModal} />
            </div>
          </div>
          {Content && <Content />}
        </Modal>
      </>
    );
  },
);
