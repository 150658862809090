import { useCallback, useContext, useEffect, useRef } from 'react';

import { NotificationsContext } from '../../../context/NotificationsContext';
import { useInfiniteNews } from '../../../hooks/useInfiniteNews';
import { useWindowSize } from '../../../hooks/useWindowSize';
import Skeleton from '../../atoms/Skeleton/Skeleton';
import styles from './News.module.scss';
import { NewsItem } from './ui/NewsItem';

const getSkeletonCount = width => {
  if (width <= 540) {
    return { perLine: 1, rows: 1 };
  }
  if (width > 540 && width <= 1200) {
    return { perLine: 2, rows: 1 };
  }
  return { perLine: 4, rows: 2 };
};

export const News = () => {
  const { setNotifications } = useContext(NotificationsContext);
  const { data = [], error, fetchNextPage, hasNextPage, isFetchingNextPage, isInitialLoading } = useInfiniteNews();
  const { width } = useWindowSize();
  const observer = useRef();

  useEffect(() => {
    if (error) {
      setNotifications({ type: 'error', message: error?.response?.data?.message });
    }
  }, [error]);

  const lastNewsElementRef = useCallback(
    node => {
      if (isFetchingNextPage || isInitialLoading) return; // Не вызываем, пока идёт начальная загрузка или запрос новой страницы

      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver(
        entries => {
          if (entries[0].isIntersecting && hasNextPage) {
            fetchNextPage();
          }
        },
        { threshold: 1 },
      );

      if (node) observer.current.observe(node);
    },
    [isFetchingNextPage, fetchNextPage, hasNextPage, isInitialLoading],
  );

  if (isInitialLoading) {
    return (
      <div className={styles.news}>
        {Array.from({ length: getSkeletonCount(width).rows }).map(el =>
          Array.from({ length: getSkeletonCount(width).perLine }).map((_, i) => (
            <Skeleton key={`skel-${i}`} className={styles.skeleton} />
          )),
        )}
      </div>
    );
  }

  return (
    <div className={styles.news}>
      {data?.pages?.map((page, pageIndex) =>
        page?.map((newsItem, newsIndex) => {
          const isLastItem = pageIndex === data.pages?.length - 1 && newsIndex === page?.length - 1;
          return (
            <NewsItem
              key={newsItem?.id}
              news={data?.pages}
              newsItem={newsItem}
              ref={isLastItem ? lastNewsElementRef : null}
            />
          );
        }),
      )}
      {isFetchingNextPage &&
        Array.from({ length: getSkeletonCount(width).perLine }).map((_, i) => (
          <Skeleton key={`skel-${i}`} className={styles.skeleton} />
        ))}
    </div>
  );
};
