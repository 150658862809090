import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useCrmCall } from '../../../../api/useCrmCall';
import { NotificationsContext } from '../../../../context/NotificationsContext';
import { UserContext } from '../../../../context/UserContext';
import { Button } from '../../../atoms/Button/Button';
import { ContentSwitcher } from '../../../atoms/ContentSwitcher/ContentSwitcher';
import { InputWithButton } from '../../../molecules/InputWithButton/InputWithButton';
import { CurrencyTransfer } from '../../../organisms/CurrencyTransfer/CurrencyTransfer';
import styles from './Bank.module.css';
import { getChangeUrl } from './getChangeUrl';
import { getMaxAmount } from './getMaxAmount';

export const Bank = ({ changeModalContent }) => {
  const { userState } = useContext(UserContext);
  const { setNotifications } = useContext(NotificationsContext);
  const [fromBalanceName, setFromBalanceName] = useState('Реальный');
  const [toBalanceName, setToBalanceName] = useState('Сейф');
  const [amount, setAmount] = useState('');
  const { postCrmRequest } = useCrmCall();
  const queryClient = useQueryClient();
  const { t } = useTranslation('Bank');

  const changeBalance = useMutation({
    mutationFn: () => postCrmRequest(`/payment${getChangeUrl(fromBalanceName)}`, { amount }),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['userData'] }),
    onError: error => setNotifications({ type: 'error', message: error?.response?.data?.message }),
  });

  const handleChange = useCallback(event => {
    setAmount(event.target.value);
  }, []);

  const handleMax = useCallback(() => {
    setAmount(getMaxAmount(fromBalanceName, userState?.crmUserData?.wallets) ?? '');
  }, [userState?.crmUserData, fromBalanceName]);

  return (
    <div className={styles.bank}>
      <div className={styles.inputsContainer}>
        <ContentSwitcher active='Safe' handleClick={changeModalContent} />
        <CurrencyTransfer
          from={fromBalanceName}
          setFromBalanceName={setFromBalanceName}
          to={toBalanceName}
          setToBalanceName={setToBalanceName}
        />
        <InputWithButton
          currency={t('currency')}
          placeholder='0.000000'
          buttonText={t('inputButtonText')}
          buttonStyle='reversed'
          labelText='Сумма перевода'
          value={amount}
          onChange={handleChange}
          onButtonClick={handleMax}
        />
      </div>
      <Button
        disabled={Number(amount) === 0}
        buttonText={t('buttonText')}
        onClick={() => (amount > 0 ? changeBalance.mutate() : null)}
      />
    </div>
  );
};
