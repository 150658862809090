import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const modalNames = {
  '/profile': 'Профиль',
  '/profile/bonuses': 'Бонусы',
  '/profile/loyalty': 'Лояльность',
  '/profile/transactions': 'История транзакций',
  '/withdrawal': 'Вывод',
  '/deposit': 'Депозит',
  '/safe': 'Сейф',
};

export const useModal = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState({
    prevSteps: [],
    current: '',
  });
  const [modalName, setModalName] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (modalNames[location.pathname]) {
      setModalName(modalNames[location.pathname]);
    }
  }, [location.pathname]);

  const openModal = useCallback(
    content => {
      setModalIsOpen(true);
      setModalContent({ prevSteps: [], current: content });
    },
    [setModalIsOpen, setModalContent],
  );

  const closeModal = useCallback(() => {
    setModalIsOpen(false);
    navigate('/');
  }, [setModalIsOpen, navigate]);

  const handleGoBack = useCallback(() => {
    if (modalContent?.prevSteps.length > 0) {
      setModalContent(prevValue => {
        const prevSteps = [...prevValue.prevSteps];
        if (prevSteps.length > 0) {
          const lastStep = prevSteps.pop();
          return {
            prevSteps,
            current: lastStep,
          };
        }
        return prevValue;
      });
    } else {
      navigate('/');
    }
  }, [modalContent, setModalContent, navigate]);

  const changeModalContent = useCallback(
    (newContent, withoutRedirect = false) => {
      setModalName(modalNames[location.pathname]);
      setModalContent(prevValue => ({
        prevSteps: [...prevValue.prevSteps, prevValue.current],
        current: newContent,
      }));

      if (typeof newContent === 'string' && !withoutRedirect) {
        navigate(`/${newContent.toLowerCase()}`);
      }
    },
    [modalNames, navigate, setModalName, setModalContent],
  );

  return {
    modalIsOpen,
    modalContent,
    modalName,
    openModal,
    closeModal,
    handleGoBack,
    setModalName,
    setModalContent,
    changeModalContent,
    setModalIsOpen,
  };
};
