import { useMutation, useQuery } from '@tanstack/react-query';
import { useContext, useEffect } from 'react';

import { useCrmCall } from '../../../../../../api/useCrmCall';
import { GamesModalContext } from '../../../../../../context/GamesModalContext';
import { NotificationsContext } from '../../../../../../context/NotificationsContext';
import Spinner from '../../../../../atoms/Spinner/Spinner';
import { ProviderItem } from '../ProviderItem/ProviderItem';

export const ModalContentProviders = () => {
  const { setNotifications } = useContext(NotificationsContext);
  const { changeModalContent, setFilteredGames, setIsLoading } = useContext(GamesModalContext);
  const { getCrmRequest, postCrmRequest } = useCrmCall();

  const {
    data: providers,
    error,
    isLoading,
  } = useQuery({
    queryKey: ['providers'],
    queryFn: () => getCrmRequest('/games/providers'),
    refetchOnWindowFocus: false,
    retryDelay: 300,
  });

  const getGamesByProvider = useMutation({
    mutationFn: key => postCrmRequest(`/games/games_by_key`, { lang: 'ru', key }),
    onSuccess: data => {
      setFilteredGames(data);
      setIsLoading(false);
    },
    onError: error => setNotifications({ type: 'error', message: error?.response?.data?.message }),
  });

  useEffect(() => {
    if (error) {
      setNotifications({ type: 'error', message: error?.response?.data?.message });
    }
  }, [error]);

  const handleClick = name => {
    setIsLoading(true)
    setFilteredGames([]);
    getGamesByProvider.mutate(name);
    changeModalContent('providers/games', name);
  };

  return isLoading ? (
    <Spinner />
  ) : (
    <>
      {providers?.map(({ id, name, main_image }) => (
        <ProviderItem key={id} name={name} image={main_image} onClick={() => handleClick(name)} />
      ))}
    </>
  );
};
