import ProviderIco from '../../../../../../assets/components/NavBar/ProviderIco.svg?react';
import { useWindowSize } from '../../../../../../hooks/useWindowSize';
import styles from './ProviderItem.module.scss';

export const ProviderItem = ({ name, onClick, image }) => {
  const { isMobile } = useWindowSize();

  return (
    <div className={styles.providerItem} onClick={onClick}>
      {image ? (
        <img src={`${import.meta.env.VITE_STATIC_DOMAIN}${image?.replace('__domain__', '')}`} alt={name} />
      ) : (
        <p className={styles.name}>{name.replace(/[-_]/gi, ' ')}</p>
      )}
    </div>
  );
};
