import { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useLocation, useNavigate } from 'react-router-dom';

import { useModal } from '../../../hooks/useModal';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { modalRoutes } from '../../../router/modalRoutes';
import { Footer } from '../../organisms/Footer/Footer';
import { Header } from '../../organisms/Header/Header';
import { NavBarBottom } from '../../organisms/NavBar/NavBarBottom/NavBarBottom';
import { News } from '../../organisms/News/News';
import { Bonuses } from '../_modals/Bonuses/Bonuses';
import { ModalComponent } from '../_modals/ModalComponent/ModalComponent';
import { FAQ } from '../FAQ/FAQ';
import { Home } from '../Home/Home';
import { Rules } from '../Rules/Rules';
import styles from './MainPage.module.scss';

Modal.setAppElement('#root');

export const MainPage = () => {
  const [content, setContent] = useState('Home');

  const [paymentType, setPaymentType] = useState('Deposite');

  const {
    modalIsOpen,
    modalContent,
    setModalContent,
    modalName,
    openModal,
    closeModal,
    handleGoBack,
    changeModalContent,
    setModalIsOpen,
  } = useModal();

  const navigate = useNavigate();
  const location = useLocation();
  const { isMobile } = useWindowSize();

  const changeContent = newContent => {
    setContent(newContent);
    if (newContent === 'Home') {
      navigate('/');
    } else if (typeof newContent === 'string') {
      navigate(`/${newContent.toLowerCase()}`);
    }
  };

  useEffect(() => {
    const path = location.pathname.slice(1);
    if (modalRoutes[path]) {
      setModalIsOpen(true);
      setModalContent({ prevSteps: [], current: path });
    } else {
      setModalIsOpen(false);
    }
  }, [location]);

  useEffect(() => {
    if (modalIsOpen) {
      document.body.classList.add('disable-scroll');
    } else {
      document.body.classList.remove('disable-scroll');
    }
  }, [modalIsOpen]);

  const componentsMap = {
    Home: <Home />,
    FAQ: <FAQ changeContent={changeContent} />,
    News: <News changeContent={changeContent} />,
    Rules: <Rules changeContent={changeContent} />,
    Bonuses: <Bonuses changeContent={changeContent} />,
  };

  const ModalContentComponent = modalRoutes[modalContent?.current];

  return (
    <>
      <Header openModal={openModal} />
      <div className={styles.contentWrapper}>{componentsMap[content]}</div>

      <ModalComponent
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        modalContent={modalContent}
        modalName={modalName}
        handleGoBack={handleGoBack}
        closeModal={closeModal}
        Content={() => (
          <ModalContentComponent
            isOpen={modalIsOpen}
            changeContent={changeContent}
            changeModalContent={changeModalContent}
            setModalContent={setModalContent}
            closeModal={closeModal}
            paymentType={paymentType}
            setPaymentType={setPaymentType}
          />
        )}
      />

      {!modalIsOpen && isMobile && <NavBarBottom />}
      {content === 'Home' && <Footer />}
    </>
  );
};
