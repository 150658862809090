import { createContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const GamesModalContext = createContext();

const GamesModalProvider = ({ children }) => {
  const [filteredGames, setFilteredGames] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalName, setModalName] = useState({});
  const [modalContent, setModalContent] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const changeModalContent = (newContent, title) => {
    // магия с обновлением стейтов, которая позволяет держать актуальный заголовок модалки. возможно работает некорректно, но свою задачу выполняет.
    // TODO: разобраться позже
    setModalName(prevValue => {
      return { prev: prevValue.current, current: title };
    });
    setModalContent(newContent);
    navigate(`/${newContent.toLowerCase()}`);
  };

  const openModal = content => {
    setModalContent(content);
    setModalIsOpen(true);
    navigate(`/${content.toLowerCase()}`);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setModalContent('');
    navigate('/');
  };

  return (
    <GamesModalContext.Provider
      value={{
        filteredGames,
        setFilteredGames,
        openModal,
        closeModal,
        modalIsOpen,
        setModalIsOpen,
        modalContent,
        setModalContent,
        changeModalContent,
        modalName,
        setModalName,
        isLoading,
        setIsLoading,
      }}
    >
      {children}
    </GamesModalContext.Provider>
  );
};

export { GamesModalContext, GamesModalProvider };
