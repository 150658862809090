// import '@radix-ui/themes/styles.css';

import { useContext, useEffect } from 'react';

import { verboxLoader } from '../verboxLoader';
import { useCrmCall } from './api/useCrmCall';
import { Notifications } from './components/organisms/Notificatiions/Notifications';
import { UserContext } from './context/UserContext';
import Routes from './router';

const initVerbox = userState => {
  if (userState?.isAuthenticated) {
    verboxLoader({
      VerboxSetup: { clientId: userState?.user?.userId },
      clientInfo: {
        clientId: userState?.user?.userId,
        phone: userState?.user?.phone,
        name: userState?.user?.username,
        custom: {
          crm_id: userState?.crmUserData?.id,
        },
      },
    });
  } else {
    verboxLoader({
      VerboxSetup: { clientId: userState?.user?.userId },
    });
  }
};

export const App = () => {
  const { userState } = useContext(UserContext);
  const { login, logout } = useContext(UserContext);
  const { postCrmRequest } = useCrmCall();
  const urlParams = new URLSearchParams(window.location.search);

  const token = localStorage.getItem('authToken');

  useEffect(() => {
    const click_id = urlParams.get('click_id');
    localStorage.setItem('ref_code', btoa(click_id));
  }, []);

  useEffect(() => {
    if (userState.user.length === 0 && token) {
      postCrmRequest(`/auth/refresh_token`)
        .then(data => {
          login({
            user: data?.user,
            token: data?.token,
          });
        })
        .catch(error => {
          logout();
        });
    }
  }, []);

  useEffect(() => {
    initVerbox(userState);
  }, [userState]);

  return (
    <>
      <Notifications />
      <Routes />
    </>
  );
};
