import { useMutation } from '@tanstack/react-query';
import { useContext, useEffect } from 'react';

import { useCrmCall } from '../../../../../../api/useCrmCall';
import { GamesModalContext } from '../../../../../../context/GamesModalContext';
import { NotificationsContext } from '../../../../../../context/NotificationsContext';
import { useDisableInteractionOnScroll } from '../../../../../../hooks/useDisableInteractionOnScroll';
import Spinner from '../../../../../atoms/Spinner/Spinner';
import { GameItem } from '../GameItem/GameItem';
import styles from './ModalContentNovelties.module.scss';

export const ModalContentNovelties = () => {
  const { filteredGames, setFilteredGames } = useContext(GamesModalContext);
  const { setNotifications } = useContext(NotificationsContext);
  const { postCrmRequest } = useCrmCall();
  useDisableInteractionOnScroll();

  const getGamesByNovelties = useMutation({
    mutationFn: () => postCrmRequest(`/games/games_by_key`, { lang: 'ru', key: 'novelties' }),
    onSuccess: data => setFilteredGames(data),
    onError: error => setNotifications({ type: 'error', message: error?.response?.data?.message }),
  });

  useEffect(() => {
    getGamesByNovelties.mutate();
  }, []);

  return (
    <div data-block-interactions={true} className={styles.gamesContainer}>
      {filteredGames.length > 0 ? filteredGames?.map(game => <GameItem key={game?.id} game={game} />) : <Spinner />}
    </div>
  );
};
