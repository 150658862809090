import { useCallback, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ModalContext } from '../../../../context/ModalContext';
import { NotificationsContext } from '../../../../context/NotificationsContext';
import { Button } from '../../../atoms/Button/Button';
import { ContentSwitcher } from '../../../atoms/ContentSwitcher/ContentSwitcher';
import { NumberInput } from '../../../molecules/NumberInput/NumberInput';
import { PaymentMethods } from '../../../organisms/PaymentMethods/PaymentMethods';
import styles from './Withdrawal.module.css';

export const Withdrawal = ({ changeModalContent, isOpen }) => {
  const { modalState, setModalState } = useContext(ModalContext);
  const { setNotifications } = useContext(NotificationsContext);
  const { t } = useTranslation('Withdrawal');

  const handleChangeAmount = e => {
    setModalState(prevState => ({ ...prevState, amount: e.target.value }));
  };

  const handleButtonClick = useCallback(() => {
    // if (modalState?.paymentMethod === 'CRYPTO') {
    //   changeModalContent('withdrawal/crypto', titles.requisites);
    // }

    if (Number(modalState?.amount) === 0) {
      setNotifications({ type: 'error', message: 'LESS_THAN_MINIMAL_SUM' });
    } else {
      changeModalContent('withdrawal-step2', true);
    }
  }, [modalState]);

  useEffect(() => {
    if (!isOpen) {
      setModalState({ requisiteNumber: '', amount: '', paymentMethod: '', bankName: '' });
    }
  }, [isOpen]);

  return (
    <div className={styles.withdrawal}>
      <ContentSwitcher active='Withdrawal' handleClick={changeModalContent} />
      <NumberInput labelText={t('labelText')} name='amount' onChange={handleChangeAmount} />
      <PaymentMethods header={t('header')} />
      <Button buttonText={t('buttonText')} onClick={handleButtonClick} />
    </div>
  );
};
