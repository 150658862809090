import styles from './PaymentCard.module.css';
import { getPaymentIcon } from './utils/getPaymentIcon';
import { useTranslation } from 'react-i18next';

export const PaymentCard = ({ methodName, onClick, className }) => {
  const { t } = useTranslation('Withdrawal');
  return (
    <div key={methodName} className={`${styles.methodCard} ${className}`} onClick={() => onClick(methodName)}>
      <span className={styles.methodsIcon}>{getPaymentIcon(methodName)}</span>
      {t(methodName)}
    </div>
  );
};
