import { useMutation } from '@tanstack/react-query';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useCrmCall } from '../../../../api/useCrmCall';
import { ModalContext } from '../../../../context/ModalContext';
import { NotificationsContext } from '../../../../context/NotificationsContext';
import { Button } from '../../../atoms/Button/Button';
import { ContentSwitcher } from '../../../atoms/ContentSwitcher/ContentSwitcher';
import { NumberInput } from '../../../molecules/NumberInput/NumberInput';
import { PaymentMethods } from '../../../organisms/PaymentMethods/PaymentMethods';
import styles from './Deposit.module.css';

export const Deposit = ({ changeModalContent, isOpen }) => {
  const { setNotifications } = useContext(NotificationsContext);
  const { modalState, setModalState } = useContext(ModalContext);
  const [error, setError] = useState();

  const { t } = useTranslation('Deposit');
  const { postCrmRequest } = useCrmCall();

  const initDeposit = useMutation({
    mutationFn: data => postCrmRequest('/payment/deposit_init', data),
    onSuccess: data => (document.location.href = data),
    onError: error => {
      setModalState({ requisiteNumber: '', amount: '', paymentMethod: '', bankName: '' });
      setNotifications({ type: 'error', message: error?.response?.data?.message });
      setError(error);
    },
  });

  const handleAmountChange = useCallback(e => {
    setModalState(prevState => ({ ...prevState, amount: e.target.value }));
  }, []);

  const depositData = {
    method: modalState?.paymentMethod,
    amount: modalState?.amount,
    redirectUrl: window?.location?.origin,
  };

  useEffect(() => {
    if (!isOpen) {
      setModalState({ requisiteNumber: '', amount: '', paymentMethod: '', bankName: '' });
    }
  }, [isOpen]);

  return (
    <div className={styles.deposit}>
      <ContentSwitcher active='Deposit' handleClick={changeModalContent} />
      <NumberInput labelText={t('labelText')} name='amount' onChange={handleAmountChange} value={modalState?.amount} />
      <PaymentMethods header={t('header')} />
      {error && <p className={styles.error}>{error?.message}</p>}
      <Button
        disabled={!modalState?.amount || !modalState?.paymentMethod}
        buttonText={t('buttonText')}
        onClick={() => initDeposit.mutate(depositData)}
      />
    </div>
  );
};
